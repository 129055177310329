/* eslint-disable import/no-cycle */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable no-nested-ternary */
import {
  ICollectionIdsFilterDTO,
  IProduct,
  ISorting,
  IOldGetInitialData,
  IGetInitialData,
  IGetCategoryProducts,
  IAddProductImpression,
} from '../types/galleryTypes';
import {SiteStore} from '@wix/wixstores-client-core/dist/es/src/viewer-script/site-store/siteStore';
import {DataApi} from '../api/DataApi';
import {GetDataQuery, ProductFilters} from '../graphql/queries-schema';
import {TRACK_EVENT_COLLECTION, origin, FedopsInteraction, trackEventMetaData, TrackEvents} from '../constants';
import {
  APP_DEFINITION_ID,
  STORAGE_PAGINATION_KEY,
  BiButtonActionType,
  AddToCartActionOption,
} from '@wix/wixstores-client-core/dist/es/src/constants';
import {IStoreFrontNavigationContext} from '@wix/wixstores-client-core/dist/es/src/types/site-map';
import {ProductActions} from '@wix/wixstores-client-core/dist/es/src/product-actions/ProductActions';
import {ITrackEventParams} from '@wix/wixstores-client-core/dist/es/src/types/wix-sdk';
import {hasSubscriptionPlans} from '@wix/wixstores-client-core/dist/es/src/productOptions/productUtils';
import {ProductsOptionsService} from './ProductsOptionsService';

export class ProductsService {
  private readonly dataApi: DataApi;
  public products: IProduct[];
  public readonly productsOptionsService = new ProductsOptionsService();
  public totalCount: number;
  public collectionName: string;
  private filters: ProductFilters = null;
  private collectionIds: ICollectionIdsFilterDTO;
  private sorting?: ISorting;
  public hideGallery = false;
  private readonly productActions: ProductActions;

  constructor(
    private readonly siteStore: SiteStore,
    private productsPerPage: number,
    private readonly consumerName: string,
    private withOptions: boolean,
    private readonly fedopsLogger
  ) {
    this.dataApi = new DataApi(this.siteStore);
    this.productActions = new ProductActions(this.siteStore);
  }

  public updateFiltersAndSort = (filters: ProductFilters, sorting?: ISorting) => {
    this.filters = filters;
    this.sorting = sorting;
  };

  private setProducts(products: IProduct[]) {
    this.products = products;
    this.productsOptionsService.addProducts(this.products);
  }

  public async getProducts(
    filters: ProductFilters,
    collectionIds: ICollectionIdsFilterDTO,
    sorting?: ISorting
  ): Promise<IProduct[]> {
    const response = await this.dataApi.getProducts(
      0,
      this.productsPerPage,
      filters,
      collectionIds,
      this.withOptions,
      sorting
    );
    this.totalCount = response.totalCount;
    this.setProducts(response.list);
    this.filters = filters;
    this.collectionIds = collectionIds;
    this.sorting = sorting;
    this.sendTrackEvent(0);
    return this.products;
  }

  public handleProductsOptionsChange({productId, selectionIds}: {productId: string; selectionIds: number[]}): void {
    const product = this.products.find((p) => p.id === productId);

    this.productsOptionsService.handleUserInput(product.id, selectionIds);
  }

  public async oldGetInitialData(options: IOldGetInitialData): Promise<GetDataQuery> {
    const {data} = await this.dataApi.oldGetInitialData({
      ...options,
      limit: this.productsPerPage,
    });
    return this.initState(data);
  }

  public async getInitialData(options: IGetInitialData): Promise<GetDataQuery> {
    let limit;
    if (options.limit) {
      limit = options.limit;
    } else {
      limit = this.productsPerPage;
    }
    const {data} = await this.dataApi.getInitialData({
      ...options,
      limit,
      withOptions: this.withOptions,
    });
    return this.initState(data);
  }

  public async getRelatedItems(options: {
    externalId: string;
    productIds: string[];
  }): Promise<Pick<GetDataQuery, 'catalog' | 'appSettings'>> {
    const {data} = await this.dataApi.getRelatedItems(options);
    const parsedData: Pick<GetDataQuery, 'catalog' | 'appSettings'> = {
      catalog: {
        category: {
          productsWithMetaData: {
            list: data.catalog.relatedProducts,
            totalCount: 16,
          },
          name: '',
          id: '',
        },
      },
      appSettings: data.appSettings,
    };
    this.initState(parsedData);
    return parsedData;
  }

  private initState(data: GetDataQuery): GetDataQuery {
    if (data.catalog.category === null) {
      data.catalog.category = {productsWithMetaData: {list: [], totalCount: 0}, id: '', name: ''};
      this.hideGallery = true;
    }

    this.setProducts(data.catalog.category.productsWithMetaData.list);
    this.collectionName = data.catalog.category.name;
    this.totalCount = data.catalog.category.productsWithMetaData.totalCount;
    this.collectionIds = {mainCategory: data.catalog.category.id};
    this.sendTrackEvent(0);
    return data;
  }

  public sendTrackEvent(fromIndex: number): void {
    if (this.siteStore.isSSR()) {
      return;
    }

    const items: IAddProductImpression[] = this.products.slice(fromIndex).map((p, i) => ({
      id: p.id,
      name: p.name,
      list: this.consumerName,
      category: TRACK_EVENT_COLLECTION,
      position: i + fromIndex,
      price: p.comparePrice || p.price,
      currency: this.siteStore.currency,
      dimension3: p.isInStock ? 'in stock' : 'out of stock',
    }));

    this.siteStore.windowApis.trackEvent('AddProductImpression', {
      appDefId: APP_DEFINITION_ID,
      contents: items,
      origin: 'Stores',
    });
  }

  public hasMoreProductsToLoad(): boolean {
    return this.products.length < this.totalCount;
  }

  public setProductsPerPage(productsPerPage: number): void {
    this.productsPerPage = productsPerPage;
  }

  public getProductPerPage(): number {
    return this.productsPerPage;
  }

  public setWithOptions(withOptions: boolean): void {
    this.withOptions = withOptions;
  }

  private removeNotUIVisibleProducts(visibleProducts: number) {
    if (visibleProducts !== this.products.length) {
      this.setProducts(this.products.slice(0, visibleProducts));
    }
  }

  public getProduct(id: string): IProduct {
    return this.products.filter((p) => p.id === id)[0];
  }

  public async getCategoryProducts({compId, limit, offset}: IGetCategoryProducts): Promise<void> {
    const {data} = await this.dataApi.getCategoryProducts({
      compId,
      limit,
      offset,
      withOptions: false,
    });
    const retrievedProducts = data.catalog.category.productsWithMetaData.list;
    this.products.splice(offset, retrievedProducts.length, ...retrievedProducts);
  }

  public async loadMoreProducts(visibleProducts: number): Promise<IProduct[]> {
    this.removeNotUIVisibleProducts(visibleProducts);
    const apiResponse = await this.dataApi.getProducts(
      visibleProducts,
      visibleProducts + this.productsPerPage,
      this.filters,
      this.collectionIds,
      this.withOptions,
      this.sorting
    );
    if (apiResponse.list.length === 0) {
      return null;
    }
    this.setProducts(this.products.concat([...apiResponse.list]));
    this.sendTrackEvent(visibleProducts);
    return this.products;
  }

  public async loadProducts(from: number, to: number): Promise<IProduct[]> {
    const apiResponse = await this.dataApi.getProducts(
      from,
      to,
      this.filters,
      this.collectionIds,
      this.withOptions,
      this.sorting
    );

    this.setProducts([...apiResponse.list]);

    return this.products;
  }

  public async sortProducts(sorting: ISorting): Promise<IProduct[]> {
    await this.getProducts(this.filters, this.collectionIds, sorting);
    return this.products;
  }

  public async filterProducts(filters: ProductFilters, collectionIds: ICollectionIdsFilterDTO): Promise<IProduct[]> {
    this.setProducts(await this.getProducts(filters, collectionIds, this.sorting));
    return this.products;
  }

  public getMainCollectionId(): string {
    return this.collectionIds.mainCategory;
  }

  public storeNavigation(pageId: string): void {
    const paginationMap = this.products.map((p) => p.urlPart);
    const history: IStoreFrontNavigationContext = {
      pageId,
      paginationMap,
    };
    this.siteStore.storage.local.setItem(STORAGE_PAGINATION_KEY, JSON.stringify(history));
  }

  public quickViewProduct(productId: string, index: number, compId?: string, externalId?: string): Promise<any> {
    const product = this.getProduct(productId);
    this.siteStore.biLogger.clickedOnProductQuickViewSf({
      productId,
      hasRibbon: !!product.ribbon,
      hasOptions: this.hasOptions(product),
      index,
    });
    this.sendClickTrackEvent(product, index);
    return this.productActions.quickViewProduct(
      productId,
      this.consumerName.split(' ').join('-'),
      product.urlPart,
      compId,
      externalId
    );
  }

  public sendClickTrackEvent(product: IProduct, index: number): void {
    this.siteStore.windowApis.trackEvent('ClickProduct', {
      appDefId: APP_DEFINITION_ID,
      id: product.id,
      origin: 'Stores',
      name: product.name,
      list: 'Grid Gallery',
      category: TRACK_EVENT_COLLECTION,
      position: index,
      price: product.comparePrice || product.price,
      currency: this.siteStore.currency,
      type: product.productType,
      sku: product.sku,
    });
  }

  private readonly hasOptions = (product: IProduct) => !!product.options.length;

  private readonly hasSubscriptionPlans = (product: IProduct) => {
    return hasSubscriptionPlans(product);
  };

  public async addToCart({
    productId,
    index,
    quantity,
    compId,
    externalId,
    action,
  }: {
    productId: string;
    index: number;
    quantity: number;
    compId: string;
    externalId: string;
    action: AddToCartActionOption;
  }): Promise<any> {
    const product = this.getProduct(productId);
    if (this.hasOptions(product) || this.hasSubscriptionPlans(product) || product.customTextFields.length) {
      this.siteStore.biLogger.clickAddToCartWithOptionsSf({
        appName: 'galleryApp',
        origin,
        hasOptions: true,
        productId,
        productType: product.productType,
        navigationClick: this.siteStore.isMobile() ? 'product-page' : 'quick-view',
      });
      return this.quickViewProduct(productId, index, compId, externalId);
    }
    this.fedopsLogger.interactionStarted(FedopsInteraction.AddToCart);
    this.reportAddToCartBI(product, index, quantity, action);
    this.trackAddToCart(product, quantity);

    return this.siteStore.cartActions.addToCart(productId, [], quantity, [], action, origin, () =>
      this.fedopsLogger.interactionEnded(FedopsInteraction.AddToCart)
    );
  }

  private trackAddToCart(product: IProduct, quantity: number) {
    const params: ITrackEventParams = {
      ...trackEventMetaData,
      id: product.id,
      name: product.name,
      price: product.comparePrice || product.price,
      currency: this.siteStore.currency,
      sku: product.sku,
      type: product.productType,
    };

    this.siteStore.windowApis.trackEvent(TrackEvents.ViewContent, params);
    this.siteStore.windowApis.trackEvent(TrackEvents.AddToCart, {...params, quantity});
  }

  private reportAddToCartBI(product: IProduct, index: number, quantity: number, action: AddToCartActionOption) {
    const shouldNavigateToCart = this.siteStore.cartActions.shouldNavigateToCart();

    const eventData = {
      buttonType: BiButtonActionType.AddToCart,
      appName: 'galleryApp',
      index,
      productId: product.id,
      hasOptions: this.hasOptions(product),
      productType: product.productType,
      origin,
      isNavigateCart: shouldNavigateToCart,
      navigationClick:
        /* istanbul ignore next: need to fix */
        action === AddToCartActionOption.MINI_CART && !shouldNavigateToCart
          ? 'mini-cart'
          : action === AddToCartActionOption.CART || (shouldNavigateToCart && action !== AddToCartActionOption.NONE)
          ? 'cart'
          : 'none',
      quantity,
    };

    this.siteStore.biLogger.clickOnAddToCartSf(eventData);
  }
}
